import { Service } from "src/app/models/service";
import { Cut } from "src/app/models/cut";
import { CutsService } from "./../../services/cuts.service";
import { MaterialService } from "src/app/services/material.service";
import { MaterialGroup } from "src/app/models/materialGroup";
import { Material } from "src/app/models/material";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { PrintService } from "src/app/services/print.service";
import { UserService } from "src/app/services/user.service";
import { ServiceService } from "src/app/services/service.service";

@Component({
  selector: "app-print-table",
  templateUrl: "./print-table.component.html",
  styleUrls: ["./print-table.component.css"]
})
export class PrintTableComponent implements OnInit {
  order: string;
  orderDetails: any;
  cuts: Cut[];

  uniqueMaterials: any[];
  material: any;
  materials: Material[];
  materialGroups: MaterialGroup[];

  services: Service[];

  getMaterials(): boolean {
    return this.uniqueMaterials.every(item => item.name);
  }

  constructor(
    private route: ActivatedRoute,
    private printService: PrintService,
    private serviceService: ServiceService,
    private materialService: MaterialService,
    private cutsService: CutsService,
    private userService: UserService // private router: Router
  ) {
    this.order = route.snapshot.params["orderIds"];
  }

  ngOnInit() {
    this.serviceService.getServices().subscribe(services => {
      this.services = services;
    });
    this.cutsService.getCuts(this.order).subscribe(cuts => {
      this.cuts = cuts;
      this.uniqueMaterials =
        cuts.length > 0
          ? [...Array.from(new Set(cuts.map((item: any) => item.material_id)))]
          : [];

      if (this.uniqueMaterials.length > 0) {
        this.uniqueMaterials.map((item, index) => {
          return this.materialService.getMaterial(item).subscribe(data => {
            this.material = data;
            this.uniqueMaterials[index] = this.material.data;
          });
        });
      }
    });
    this.materialService.getMaterialGroups().subscribe(group => {
      this.materialGroups = group;
    });

    setTimeout(() => {
      this.printService.onDataReady();
    }, 1500);
    if (this.uniqueMaterials && this.getMaterials()) {
    }
  }

  getFilteredCuts(id: number) {
    return this.cuts
      .filter(item => item.material_id == id)
      .sort((a, b) => a.id - b.id);
  }

  getService(item: string) {
    let title: any = this.services
      ? this.services.filter(trim => trim.id == +item)[0]
      : "";
    return title.title;
  }

  getGroupTitle(item: any) {
    let title: any = this.materialGroups
      ? this.materialGroups.filter(group => group.id == item)[0]
      : "";
    return title.name;
  }

  totalCuts(id: number, type: string) {
    const filteredCuts = this.getFilteredCuts(id);
    const initialValue = 0;

    if (type === "material") {
      return filteredCuts
        .reduce((value, item) => value + item.price_material, initialValue)
        .toFixed(2);
    } else if (type === "service") {
      return filteredCuts
        .reduce((value, item) => value + item.price_service, initialValue)
        .toFixed(2);
    } else if (type === "square") {
      return filteredCuts
        .reduce(
          (value, item) =>
            value + (item.width / 1000) * (item.height / 1000) * item.count,
          initialValue
        )
        .toFixed(2);
    } else if (type === "count") {
      return filteredCuts.reduce(
        (value, item) => value + +item.count,
        initialValue
      );
    }
  }

  getUniqueTrims(id: number) {
    let cuts = this.getFilteredCuts(id);

    let uniqueTrims = [
      ...Array.from(new Set(cuts.map((item: any) => item.cleft))),
      ...Array.from(new Set(cuts.map((item: any) => item.cright))),
      ...Array.from(new Set(cuts.map((item: any) => item.ctop))),
      ...Array.from(new Set(cuts.map((item: any) => item.cbottom)))
    ];
    uniqueTrims = [
      ...Array.from(new Set(uniqueTrims.map((item: any) => item)))
    ].filter(item => item);
    return uniqueTrims;
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { map } from "rxjs/operators";
import { Data } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class DepartmentService {
  constructor(private http: HttpClient) {}

  getDepartments() {
    return this.http
      .get("department/index")
      .pipe(map((data: Data) => data.data));
  }
}

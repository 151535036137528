import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { map } from "rxjs/operators";
import { Data } from "../models/data";
import { ErrorService } from "./error.service";
import { StorageService } from "./storage.service";
import { Router } from "@angular/router";
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: "root"
})
export class OrderService {
  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private storageService: StorageService,
    private loaderService: LoaderService,
    private router: Router
  ) {}

  makeOrder(id: string) {
    this.loaderService.setLoader(true);
    let formData = new FormData();

    formData.append("material_id", id);

    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http.post(`orders/create`, formData, requestOptions).subscribe(
      (data: Data) => {
        this.storageService.setItem("orderID", data.data.id);
        this.router.navigate(["/order"]);
        this.errorService.setError("");

        this.loaderService.setLoader(false);
      },
      error =>  {if (error.status === 403) {
        this.storageService.removeItem("token");
        this.router.navigateByUrl("/signing");
      } else {
        this.errorService.setError(error.error.error);
      }
    }
    );
  }

  cloneOrder(id: string) {
    let formData = new FormData();

    formData.append("id", id);

    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http.post(`orders/clone`, formData, requestOptions).subscribe(
      (data: Data) => {
        this.storageService.setItem("orderID", data.data.id);
        this.router.navigate(["/order"]);
        this.errorService.setError("");
      },
      error => this.errorService.setError(error.error.error)
    );
  }

  commentOrder(id: string, comment: string) {
    let formData = new FormData();

    formData.append("id", id);
    formData.append("comment", comment);

    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http
      .post(`orders/comment`, formData, requestOptions)
      .subscribe(
        (data: Data) => {},
        error => this.errorService.setError(error.error.error)
      );
  }

  deleteOrder(id: string) {
    let formData = new FormData();

    formData.append("id", id);

    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http.post(`orders/delete`, formData, requestOptions).pipe(
      map((data: any) => {
        this.errorService.setError("");
      })
    );
  }

  getOrders() {
    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http.get(`orders?offset=0&limit=10`, requestOptions).pipe(
      map((data: Data) => {
        this.errorService.setError("");
        return data;
      })
    );
  }

  getMoreOrders(page) {
    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http.get(`orders?offset=${page}&limit=10`, requestOptions).pipe(
      map((data: Data) => {
        this.errorService.setError("");
        return data;
      })
    );
  }

  getFile(id: any) {
    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http.get(`orders/${id}/file`, requestOptions).pipe(
      map((data: Data) => {
        this.errorService.setError("");
        return data.data;
      })
    );
  }

  sendOrder(id: any, orderData) {
    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };

    let formData = new FormData();

    for (var key in orderData) {
      if (key === "file") {
        orderData[key].map((item, index) =>
          formData.append(`${key}[${index}]`, item)
        );
      } else {
        formData.append(key, orderData[key]);
      }
    }

    return this.http
      .post(`orders/${id}/send`, formData, requestOptions)
      .pipe(map((data: any) => data.data));
  }
}

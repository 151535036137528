import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { map } from "rxjs/operators";
import { Data } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class ServiceService {
  constructor(private http: HttpClient) {}

  getServices() {
    return this.http.get("trim/index").pipe(map((data: Data) => data.data));
  }
}

import { CutsService } from "./../../services/cuts.service";
import { DepartmentService } from "./../../services/department.service";
import { Department } from "src/app/models/department";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ServiceService } from "src/app/services/service.service";
import { Service } from "src/app/models/service";
import { ErrorService } from "src/app/services/error.service";
import { OrderService } from "src/app/services/order.service";
import { Cut } from "src/app/models/cut";
import { Material } from "src/app/models/material";
import { MaterialGroup } from "src/app/models/materialGroup";
import { MaterialService } from "src/app/services/material.service";

import * as $ from "jquery";
import "bootstrap";
import { StorageService } from "src/app/services/storage.service";

import { UserService } from "src/app/services/user.service";
import { User } from "src/app/models/user";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.css"]
})
export class OrderComponent implements OnInit {
  services: Service[];
  departments: Department[];
  editedCut = {
    id: "",
    order_id: "",
    material_id: "",
    height: "",
    width: "",
    count: "",
    rotation: 0,
    cleft: "",
    cright: "",
    ctop: "",
    cbottom: ""
  };

  selectedCut = {
    id: "",
    order_id: "",
    material_id: "",
    height: "",
    width: "",
    count: "",
    rotation: 0,
    cleft: "",
    cright: "",
    ctop: "",
    cbottom: "",
    price_material: 0,
    price_service: 0
  };

  trim: number;
  trimName: string;
  trimType: string;
  cuts: Cut[];
  materialCuts: any[];
  uniqueMaterials: any[];
  uniqueMaterial: any = 0;
  materials: Material[];
  materialGroups: MaterialGroup[];
  productGroup: any;
  productId: any;
  material: any;

  deleteItem: any;

  serviceRows = [];
  selectedTrims = [];
  sizes = [];
  size = {
    height: 2800,
    id: 0,
    width: 2070
  };

  data: any;
  user: User;
  orderFile: any = "";
  sendOrderFile: any;
  department_id = 1;
  title: string;
  body: string;
  trimsQnt: number;
  trims: any[];

  constructor(
    private serviceService: ServiceService,
    private materialService: MaterialService,
    private departmentService: DepartmentService,
    private cutsService: CutsService,

    private userService: UserService,
    private storageService: StorageService,
    private orderService: OrderService,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    this.cutsService.$editedCut.subscribe(val => {
      this.selectedCut = val;
      this.getServiceRows();
    });
    this.cutsService.$trimsQnt.subscribe(val => {
      this.trimsQnt = val;
    });
    this.cutsService.$trims.subscribe(val => {
      this.trims = val;
    });
    this.serviceService.getServices().subscribe(services => {
      this.services = services;
    });
    this.materialService.getMaterialGroups().subscribe(group => {
      this.materialGroups = group;
    });
  }

  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  handleServicesChange(evt) {
    let target = evt.target;
    let service = this.services.findIndex(el => el.id == target.value);
    // if (this.trimsQnt > 4 && this.trims.every(item => item !== +target.value)) {
    //   this.errorService.setError(
    //     "Можно использовать максимум 5 типов торцевания для одного материала"
    //   );
    // } else {
    this.trim = +target.value;
    // if (this.trims.every(item => item !== this.trim)) {
    //   ++this.trimsQnt;
    //   console.log(this.trimsQnt);
    this.trims.push(this.trim);
    // }
    let shouldAdd = this.selectedTrims.some(item => item.id === +target.value);
    this.trimName = this.services[service].title;
    this.trimType = this.services[service].value.split("/")[0];
    !shouldAdd ? this.selectedTrims.push(this.services[service]) : "";
    this.selectedTrims = this.selectedTrims.slice(-3);
    // }
  }

  onTrimClick(side: string) {
    if (this.trim || this.trim === 0) {
      this.selectedCut[`c${side}`] === ""
        ? (this.selectedCut[`c${side}`] = this.trim)
        : (this.selectedCut[`c${side}`] = "");
      this.cutsService.setEditedCut(this.selectedCut);
      this.getServiceRows();
    } else {
      this.errorService.setError("Выберите торцевание");
    }
  }

  onTrimDelete() {
    this.selectedCut.ctop = "";
    this.selectedCut.cbottom = "";
    this.selectedCut.cleft = "";
    this.selectedCut.cright = "";
    this.cutsService.setEditedCut(this.selectedCut);
    this.serviceRows = [];
  }

  getService(item: any, type: string) {
    let title: any = this.services
      ? this.services.filter(trim => trim.id == item[type])[0]
      : "";
    return title;
  }

  getGroupTitle(item: any) {
    let title: any = this.materialGroups
      ? this.materialGroups.filter(group => group.id == item)[0]
      : "";
    return title.name;
  }

  onSelectChange(value: any, type: string) {
    if (type === "group") {
      this.materialService
        .getMaterials(this.productGroup)
        .subscribe(materials => {
          this.materials = materials.map(item => item);
        });
    } else {
      this.productId = value;
    }
  }

  getServiceRows() {
    let serviceRows = [
      this.selectedCut.ctop,
      this.selectedCut.cbottom,
      this.selectedCut.cright,
      this.selectedCut.cleft
    ];
    this.serviceRows = [
      ...Array.from(new Set(serviceRows.map((item: any) => item)))
    ]
      .filter(item => !!item || item === 0)
      .map(item => {
        return {
          type:
            item === this.selectedCut.ctop
              ? "ctop"
              : item === this.selectedCut.cbottom
              ? "cbottom"
              : item === this.selectedCut.cright
              ? "cright"
              : "cleft",
          value: item,
          width:
            (item === this.selectedCut.ctop ? 1 : 0) +
            (item === this.selectedCut.cbottom ? 1 : 0),
          height:
            (item === this.selectedCut.cright ? 1 : 0) +
            (item === this.selectedCut.cleft ? 1 : 0)
        };
      });
  }
}

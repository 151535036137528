import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.css"]
})
export class HelpComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  onTrimClick() {
    let item = document.getElementById("scrollTo");
    item.scrollIntoView();
  }
}

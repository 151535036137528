import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { Data } from "../models/data";
import { StorageService } from "./storage.service";
import { ErrorService } from "./error.service";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
    private errorService: ErrorService
  ) {}

  signIn(data) {
    let formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    return this.http.post("user/login", formData);
  }

  signUp(data) {
    let formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    return this.http.post("user/register", formData);
  }

  forgetPassword(data) {
    let formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    return this.http.post("user/recovery_request", formData);
  }

  recoveryPassword(data) {
    let formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    return this.http.post("/user/recovery", formData);
  }
}

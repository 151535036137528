import { ShowOrderComponent } from './pages/show-order/show-order.component';
import { PrintTableComponent } from './components/print-table/print-table.component';
import { PrintLayoutComponent } from './pages/print-layout/print-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { ProductComponent } from './pages/product/product.component';
import { SigningComponent } from './pages/signing/signing.component';
import { ServicesComponent } from './pages/services/services.component';
import { MaterialsComponent } from './pages/materials/materials.component';
import { CabinetComponent } from './pages/cabinet/cabinet.component';
import { EditOrderComponent } from './pages/edit-order/edit-order.component';
import { HelpComponent } from './pages/help/help.component';
import { FacilitiesComponent } from './pages/facilities/facilities.component';
import { OrderComponent } from './pages/order/order.component';

const routes: Routes = [
	{ path: '', component: MainComponent },
	{ path: 'signing', component: SigningComponent },
	{ path: 'services', component: ServicesComponent },
	{ path: 'materials', component: MaterialsComponent },
	{ path: 'product/:id', component: ProductComponent },
	{ path: 'cabinet', component: CabinetComponent },
	{ path: 'edit-order', component: EditOrderComponent },
	{ path: 'help', component: HelpComponent },
	{ path: 'facilities', component: FacilitiesComponent },
	{ path: 'order', component: OrderComponent },
	{ path: 'order/:id', component: OrderComponent },
	{ path: 'edit-order/:orderIds', component: EditOrderComponent },
	{ path: 'order/show/:orderIds', component: ShowOrderComponent },
	{
		path: 'print',
		outlet: 'print',
		component: PrintLayoutComponent,
		children: [ { path: 'table/:orderIds', component: PrintTableComponent } ]
	}
];

@NgModule({
	imports: [ RouterModule.forRoot(routes) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}

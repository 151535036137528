import { NgxMaskModule } from "ngx-mask";
import { NgModule } from "@angular/core";
import { ErrorService } from "src/app/services/error.service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { OrderService } from "src/app/services/order.service";
import { StorageService } from "src/app/services/storage.service";
import { Data } from "src/app/models/data";

@Component({
  selector: "app-signing",
  templateUrl: "./signing.component.html",
  styleUrls: ["./signing.component.css"]
})
@NgModule({
  imports: [NgxMaskModule.forChild()]
})
export class SigningComponent implements OnInit {
  signin = { login: "", password: "" };
  forgotten = {
    email: ""
    // , password: ""
  };
  passwordRecovery = {
    email: "",
    password: "",
    token: ""
  };
  signup = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    password_confirm: ""
  };
  notSame: boolean = false;
  length: boolean = false;
  path: boolean;

  constructor(
    private authService: AuthService,

    private orderService: OrderService,
    private router: Router,

    private errorService: ErrorService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.path = this.router.url.includes("/product");
  }

  onPasswordChange(value) {
    this.length = value.length < 6;
  }

  onPasswordConfirmChange(value) {
    this.notSame
      ? (this.notSame = this.signup.password === this.signup.password_confirm)
      : "";
  }

  onSignInSubmit() {
    let id = localStorage.getItem("product")
      ? JSON.parse(localStorage.getItem("product")).id
      : "";
    this.authService.signIn(this.signin).subscribe(
      (data: Data) => {
        this.errorService.setError("");
        if (data.data) {
          this.storageService.setItem("token", data.data.token);
          if (this.path) {
            this.storageService.setItem("productID", id);
            this.orderService.makeOrder(id);
          } else {
            setTimeout(() => this.router.navigate(["/cabinet"]), 500);
          }
        }
      },
      error => this.errorService.setError(error.error.error)
    );
  }

  onSignUpSubmit() {
    if (this.signup.password !== this.signup.password_confirm) {
      this.notSame = true;
    } else {
      let id = localStorage.getItem("product")
        ? JSON.parse(localStorage.getItem("product")).id
        : "";
      this.authService.signUp(this.signup).subscribe(
        (data: Data) => {
          if (data.data) {
            this.storageService.setItem("token", data.data.token);
            if (this.path) {
              this.storageService.setItem("productID", id);
              this.orderService.makeOrder(id);
            } else {
              setTimeout(() => this.router.navigate(["/cabinet"]), 500);
            }
          }
        },
        error => this.errorService.setError(error.error.error)
      );
    }
  }
  onForgetPassword() {
    if (this.forgotten.email) {
      this.authService
        .forgetPassword(this.forgotten)
        .subscribe(
          (data: Data) =>
            this.errorService.setError(
              "Ключ відновлення пароля був відправлений Вам на email"
            ),
          error => this.errorService.setError(error.error.error)
        );
    }
  }
  onRecoveryPassword() {
    this.authService
      .recoveryPassword(this.passwordRecovery)
      .subscribe(
        (data: Data) =>
          this.errorService.setError("Ви успішно змінили пароль"),
        error => this.errorService.setError(error.error.error)
      );
  }
}

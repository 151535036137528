import { Component, OnInit } from "@angular/core";
import { ErrorService } from "src/app/services/error.service";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.css"]
})
export class ToastComponent implements OnInit {
  error: string;

  constructor(private errorService: ErrorService) {}

  ngOnInit() {
    this.errorService.watchError().subscribe(data => {
      this.error = data;
      setTimeout(() => (this.error = ""), 5000);
    });
  }

  onToastClose() {
    this.errorService.setError("");
  }
}

import { Subject } from "rxjs";
import { Material } from "./../models/material";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { map } from "rxjs/operators";
import { Data } from "../models/data";
import { ErrorService } from "./error.service";
import { Cut } from "../models/cut";

@Injectable({
  providedIn: "root"
})
export class CutsService {
  private editedCut = new Subject<Cut>();
  private trimsQnt = new Subject<number>();
  private trims = new Subject<any[]>();

  public $editedCut = this.editedCut.asObservable();
  public $trimsQnt = this.trimsQnt.asObservable();
  public $trims = this.trims.asObservable();
  constructor(private http: HttpClient, private errorService: ErrorService) {}

  getCuts(id: string) {
    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http.get(`cut/${id}`, requestOptions).pipe(
      map((data: Data) => {
        this.errorService.setError("");
        return data.data;
      })
    );
  }

  createCut(сutData) {
    let formData = new FormData();

    for (var key in сutData) {
      formData.append(key, сutData[key]);
    }

    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http
      .post(`cut/create`, formData, requestOptions)
      .pipe(map((data: any) => data.data));
  }

  updateCut(сutData) {
    let formData = new FormData();

    for (var key in сutData) {
      formData.append(key, сutData[key]);
    }

    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http
      .post(`cut/update`, formData, requestOptions)
      .pipe(map((data: any) => data.data));
  }

  deleteCut(id: any) {
    let formData = new FormData();

    formData.append("id", id);

    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http
      .post(`cut/delete`, formData, requestOptions)
      .pipe(map((data: any) => data.data));
  }

  deleteMaterialCut(order: number, material: number) {
    let formData = new FormData();

    formData.append("order", `${order}`);
    formData.append("material", `${material}`);

    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http
      .post(`cut/delete_material`, formData, requestOptions)
      .pipe(map((data: any) => data.data));
  }

  editMaterialCut(id: number, former_material: number, new_material: number) {
    let formData = new FormData();

    formData.append("id", `${id}`);
    formData.append("former_material", `${former_material}`);
    formData.append("new_material", `${new_material}`);

    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http
      .post(`orders/change`, formData, requestOptions)
      .pipe(map((data: any) => data.data));
  }

  setEditedCut(cut: any) {
    this.editedCut.next(cut);
  }
  setTrimQnt(length: any) {
    this.trimsQnt.next(length);
  }
  setTrims(trims: any[]) {
    this.trims.next(trims);
  }
}

import { CutsService } from "./../../services/cuts.service";
import { MaterialService } from "./../../services/material.service";
import { Router } from "@angular/router";
import { StorageService } from "./../../services/storage.service";
import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";
import { OrderService } from "src/app/services/order.service";
import { Order } from "src/app/models/order";
import { Department } from "src/app/models/department";
import { DepartmentService } from "src/app/services/department.service";
import { ErrorService } from "src/app/services/error.service";

import { environment } from 'src/environments/environment';

import * as $ from "jquery";
import "bootstrap";

@Component({
  selector: "app-cabinet",
  templateUrl: "./cabinet.component.html",
  styleUrls: ["./cabinet.component.css"]
})
export class CabinetComponent implements OnInit {
  orders: Order[];
  logged: boolean = false;
  apiUrl = environment.apiUrl;

  user: User = {
    first_name: "",
    last_name: "",
    email: "",
    phone: ""
  };

  departments: Department[];
  pagination = [];
  currentPage = 0;
  uniqueMaterials = [];
  uniqueMaterials2 = [];
  orderMaterials = [];
  deletedOrder: any;
  orderFile: any = "";
  sendOrderFile: any;
  order_id: any;
  department_id = 1;
  title: string;
  body: string;
  comment: string;
  orderFileNames: string[];

  constructor(
    private orderService: OrderService,
    private departmentService: DepartmentService,
    private storageService: StorageService,
    private router: Router,
    private errorService: ErrorService,
    private materialService: MaterialService,
    private userService: UserService,
    private cutsService: CutsService
  ) {}

  ngOnInit() {
    this.logged = !!localStorage.getItem("token");
    this.userService
      .getUser()
      .subscribe(user => (this.user = this.userService.user = user));
    this.orderService.getOrders().subscribe(
      orders => {
        this.pagination = Array.from(
          { length: Math.ceil(+orders.count / 10) },
          (v, k) => k
        );
        this.orders = orders.data;
        this.orders.map((item, index) => {
          this.getMaterials(item.id, index);
        });
      },
      error => {
        if (error.status === 403) {
          this.storageService.removeItem("token");
          this.router.navigateByUrl("/signing");
        } else {
          this.errorService.setError(error.error.error);
        }
      }
    );

    this.departmentService
      .getDepartments()
      .subscribe(
        departments => (this.departments = departments),
        error => this.errorService.setError(error.error.error)
      );
  }

  ngDoCheck() {
    this.logged = !!localStorage.getItem("token");
  }

  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  setDate(date: string) {
    let formatDate = new Date(date);
    let day = formatDate.getDate();
    let month = formatDate.getMonth() + 1;
    let year = `${formatDate.getFullYear()}`.slice(2);
    return `${day}.${month < 10 ? "0" + month : month}.${year}`;
  }

  onOrderDeleteClick() {
    this.currentPage =
      this.orders.length === 1 && this.currentPage > 0
        ? this.currentPage - 1
        : this.currentPage;
    this.orderService.deleteOrder(this.deletedOrder).subscribe((data: any) =>
      this.orderService.getMoreOrders(this.currentPage).subscribe(
        orders => {
          this.pagination = Array.from(
            { length: Math.ceil(+orders.count / 10) },
            (v, k) => k
          );
          this.orders = orders.data;
          this.orders.map((item, index) => {
            this.getMaterials(item.id, index);
          });
        },
        error => this.errorService.setError(error.error.error)
      )
    );
  }

  onOrderEdit(id) {
    this.storageService.setItem("orderID", id);
    this.router.navigate([`/order/${id}`]);
  }

  onOrderCopy(id) {
    this.orderService.cloneOrder(id);
  }

  paginationClick(page, type: string) {
    if (page !== -1 && page < this.pagination.length) {
      this.uniqueMaterials = [];
      this.uniqueMaterials2 = [];
      this.orderMaterials = [];
      this.currentPage = page;
      this.orderService.getMoreOrders(page * 10).subscribe(
        orders => {
          this.pagination = Array.from(
            { length: Math.ceil(+orders.count / 10) },
            (v, k) => k
          );
          this.orders = orders.data;
          this.orders.map((item, index) => {
            this.getMaterials(item.id, index);
          });
        },
        error => this.errorService.setError(error.error.error)
      );
    }
  }

  getMaterials(id, index) {
    this.cutsService.getCuts(id).subscribe(cuts => {
      this.uniqueMaterials =
        cuts.length > 0
          ? [...Array.from(new Set(cuts.map((item: any) => item.material_id)))]
          : [];
      this.uniqueMaterials = this.uniqueMaterials.map((material, i) => {
        this.materialService.getMaterial(material).subscribe(data => {
          this.orderMaterials[index][i] = data.data;
        });
      });
      this.orderMaterials[index] = this.uniqueMaterials;
    });
  }

  showOrderMaterials(index) {
    if (
      this.orderMaterials[index] &&
      this.orderMaterials[index].filter(item => item).length ==
        this.orderMaterials[index].length
    ) {
      return this.orderMaterials[index];
    }
  }

  onOrderDelete(id: any) {
    this.deletedOrder = id;
  }

  onGetFileClick(id: any) {
    let filename = "file.fzs";
    this.orderService.getFile(id).subscribe(
      (data: any) => {
        this.orderFile = data;

        if (this.orderFile) {
          let blob = new Blob([this.orderFile], { type: "text/plain" }),
            a = document.createElement("a");

          a.download = filename;
          a.href = window.URL.createObjectURL(blob);
          a.click();

          this.orderFile = "";
        }
      },
      error => this.errorService.setError(error.error.error)
    );
  }

  handleFileInput(files: any) {
    this.sendOrderFile = this.sendOrderFile
      ? [...this.sendOrderFile, ...Array.from(files)]
      : Array.from(files);
    this.getOrderFileNames();
  }

  onSendOrderClick(id: any) {
    let orderData: any = {
      craft_id: this.department_id,
      title: this.title,
      body: this.body
    };

    if (this.sendOrderFile) {
      orderData.file = this.sendOrderFile;
    }

    this.orderService
      .sendOrder(this.order_id, orderData)
      .subscribe((data: any) => {
        if (data) {
          this.body = "";
          this.sendOrderFile = "";
          this.orderService.getMoreOrders(this.currentPage).subscribe(
            orders => {
              this.orders = orders.data;
              this.orders.map((item, index) => {
                this.getMaterials(item.id, index);
              });
            },
            error => this.errorService.setError(error.error.error)
          );
        }
      });
  }

  onSendClick(id: any) {
    this.order_id = id;
    this.title = `№ Заказа ${this.order_id} от ${this.user.first_name} ${
      this.user.last_name
    }`;
  }

  onSelectChange(id: any) {
    this.department_id = id;
  }

  getDepartmentId(id: number) {
    return this.departments.filter(department => department.id === id)[0]
      .address;
  }

  onShowOrderClick(id: number) {
    this.router.navigateByUrl(`/order/show/${id}`);
  }

  getOrderFileNames() {
    let urlArray = [];

    this.sendOrderFile.map(item => {
      let reader = new FileReader();
      reader.onload = () => {
        item.type.split("/")[0] === "image"
          ? urlArray.push(reader.result)
          : urlArray.push(item.name);
      };
      reader.readAsDataURL(item);
    });
    this.orderFileNames = urlArray;
  }

  commentOrder(id: string, comment: string) {
    this.orderService.commentOrder(id, comment);
  }

  onOrderFileDelete(index: number) {
    this.orderFileNames = this.orderFileNames.filter((item, i) => index !== i);
    this.sendOrderFile = this.sendOrderFile.filter((item, i) => index !== i);
  }

  checkStringBase(index: number) {
    return this.sendOrderFile[index].type.split("/")[0] === "image";
  }
}

import { Subject } from "rxjs";
import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { map } from "rxjs/operators";
import { Material } from "../models/material";
import { Router, Data } from "@angular/router";
import { LoaderService } from "./loader.service";

@Injectable({
  providedIn: "root"
})
export class MaterialService {
  public material: Material;
  public materials: Material[];
  private materialGroup = new Subject<number>();

  public $materialGroup = this.materialGroup.asObservable();
  loader: boolean = true;

  constructor(
    private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService
  ) {}

  getMaterials(id = 1) {
    return this.http
      .get(`material/${id}/list`)
      .pipe(map((data: Data) => data.data.filter(item => !item.disabled)));
  }

  getMaterialGroups() {
    return this.http.get("material/group").pipe(map((data: Data) => data.data));
  }

  searchMaterials(name) {
    this.loader = false;
    this.loaderService.setLoader(this.loader);

    this.router.navigate(["/"]);
    return this.http.get(`material/search?name=${name}`).pipe(
      map((data: Data) => {
        this.loader = false;
        this.loaderService.setLoader(this.loader);
        return data.data.filter(item => !item.disabled);
      })
    );
  }

  getMaterial(id) {
    return this.http.get(`material/view/${id}`).pipe(map((data: any) => data));
  }

  setMaterialGroup(id: number) {
    this.materialGroup.next(id);
  }
}

import { Component, OnInit } from "@angular/core";
import { Service } from "src/app/models/service";
import { ServiceService } from "src/app/services/service.service";
import { LoaderService } from "src/app/services/loader.service";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.css"]
})
export class ServicesComponent implements OnInit {
  services: Service[];
  loader: boolean = true;

  constructor(
    private serviceService: ServiceService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.loaderService.$loader.subscribe(val => {
      this.loader = val;
    });
    this.loaderService.setLoader(true);
    this.serviceService.getServices().subscribe(services => {
      this.loaderService.setLoader(false);
      this.services = services;
    });
  }
}

import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { MenuToggleService } from "src/app/services/menu-toggle.service";
import { StorageService } from "src/app/services/storage.service";

import * as $ from "jquery";
import "bootstrap";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.css"]
})
export class SidenavComponent implements OnInit {
  active: boolean = false;
  logged: boolean = false;

  constructor(
    private menuToggleService: MenuToggleService,
    private storageService: StorageService,
    private router: Router
  ) {}

  public toggleBox(): void {
    this.menuToggleService.setToggle(false);
  }

  ngOnInit() {
    this.logged = !!localStorage.getItem("token");
    this.menuToggleService.$toggle.subscribe(val => {
      this.active = val;
    });
    this.storageService.watchStorage().subscribe(data => {
      if (data.key === "token") {
        this.logged = data.type === "set";
      }
    });
  }

  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  onLogout() {
    this.storageService.removeItem("token");
    this.router.navigate(["/"]);
  }

  isActive(path) {
    return location.pathname === path;
  }
}

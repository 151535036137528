import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ErrorService {
  private error = new Subject<string>();

  constructor() {}

  watchError(): Observable<any> {
    return this.error.asObservable();
  }

  setError(val: string) {
    this.error.next(val);
  }
}

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { MainComponent } from "./pages/main/main.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { ProductComponent } from "./pages/product/product.component";
import { SigningComponent } from "./pages/signing/signing.component";
import { ServicesComponent } from "./pages/services/services.component";
import { MaterialsComponent } from "./pages/materials/materials.component";
import { CabinetComponent } from "./pages/cabinet/cabinet.component";
import { EditOrderComponent } from "./pages/edit-order/edit-order.component";
import { HelpComponent } from "./pages/help/help.component";
import { FacilitiesComponent } from "./pages/facilities/facilities.component";

import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { Observable } from "rxjs";
import { LoaderComponent } from "./components/loader/loader.component";
import { ToastComponent } from "./components/toast/toast.component";
import { OrderComponent } from "./pages/order/order.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { UpdateUserComponent } from "./components/update-user/update-user.component";
import { CutsTableComponent } from "./components/cuts-table/cuts-table.component";

import { NgxMaskModule, IConfig } from "ngx-mask";
import { PrintLayoutComponent } from "./pages/print-layout/print-layout.component";
import { PrintTableComponent } from "./components/print-table/print-table.component";
import { ShowOrderComponent } from './pages/show-order/show-order.component';

export let options: Partial<IConfig> | (() => Partial<IConfig>);

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiReq = req.clone({
      url: `https://master.porezka.net/porezka-ua/dist/porezka/api/${req.url}`
    });
    return next.handle(apiReq);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    SidenavComponent,
    ProductComponent,
    SigningComponent,
    ServicesComponent,
    MaterialsComponent,
    CabinetComponent,
    EditOrderComponent,
    HelpComponent,
    FacilitiesComponent,
    LoaderComponent,
    ToastComponent,
    OrderComponent,
    ChangePasswordComponent,
    UpdateUserComponent,
    CutsTableComponent,
    PrintLayoutComponent,
    PrintTableComponent,
    ShowOrderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxMaskModule.forRoot(options)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

/// <reference types="@types/googlemaps" />;
import { Component, OnInit, ViewChild } from "@angular/core";
import { Department } from "src/app/models/department";
import { DepartmentService } from "src/app/services/department.service";
import { LoaderService } from "src/app/services/loader.service";
// import {} from "@types/googlemaps";

@Component({
  selector: "app-facilities",
  templateUrl: "./facilities.component.html",
  styleUrls: ["./facilities.component.css"]
})
export class FacilitiesComponent implements OnInit {
  // @ViewChild("gmap") gmapElement: any;
  // map: google.maps.Map;

  departments: Department[];
  loader: boolean = true;

  constructor(
    private departmentService: DepartmentService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    // const mapProp = {
    //   center: new google.maps.LatLng(49.9935, 36.2304),
    //   zoom: 12,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP
    // };
    // this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    this.loaderService.$loader.subscribe(val => {
      this.loader = val;
    });
    this.loaderService.setLoader(true);

    this.departmentService.getDepartments().subscribe(departments => {
      this.loaderService.setLoader(false);
      this.departments = departments.map(item => {
        switch (item.id) {
          case 1:
            item.coords = [49.9465202, 36.2811202];
            break;
          case 2:
            item.coords = [50.054855, 36.1936383];
            break;
          case 3:
            item.coords = [49.9607246, 36.2830272];
            break;
          case 4:
            item.coords = [49.9693563, 36.2521717];
            break;
          case 5:
            item.coords = [49.9833187, 36.2085117];
            break;
          case 6:
            item.coords = [49.9833187, 36.2085117];
            break;
          case 7:
            item.coords = [49.9692124, 36.2561604];
            break;
          case 8:
            item.coords = [50.0408597, 36.2250008];
            break;
          default:
            item.coords = [];
        }
        // var latLng = new google.maps.LatLng(item.coords[0], item.coords[1]);
        // var marker = new google.maps.Marker({
        //   position: latLng,
        //   label: `${item.id}`,
        //   map: this.map,
        //   title: item.address
        // });

        // var infowindow = new google.maps.InfoWindow({
        //   content: item.address
        // });

        // marker.addListener("click", () => {
        //   infowindow.open(this.map, marker);
        // });

        return item;
      });
    });
  }

  onFacilityClick(department: any) {
    let elmnt = document.querySelector(".facilities"),
      map = <HTMLImageElement>document.getElementById("map"),
      address = `${department.address.replace(/\s/g, "+")}+Харьков`;
    map.src = `${map.src.split("q=")[0]}q=${address}`;
    // this.map.setZoom(18);
    // this.map.setCenter(
    //   new google.maps.LatLng(department.coords[0], department.coords[1])
    // );

    elmnt.scrollIntoView({ block: "start", behavior: "smooth" });
  }
}

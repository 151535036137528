import { Component, OnInit } from "@angular/core";
import { LoaderService } from "src/app/services/loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.css"]
})
export class LoaderComponent implements OnInit {
  loader: boolean = true;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService.$loader.subscribe(val => {
      this.loader = val;
    });
  }
}

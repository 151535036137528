import { ErrorService } from "./error.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { map } from "rxjs/operators";
import { User } from "../models/user";
import { Data } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class UserService {
  public user: User;

  constructor(private http: HttpClient, private errorService: ErrorService) {}

  getUser() {
    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http
      .get(`user/profile`, requestOptions)
      .pipe(map((data: Data) => data.data));
  }

  changePassword(data: any) {
    let formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http.post(`user/password`, formData, requestOptions).subscribe(
      (data: Data) => {
        localStorage.setItem("token", data.data.token);
      },
      error => this.errorService.setError(error.error.error)
    );
  }

  updateUser(data: any) {
    let formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    const requestOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") })
    };
    return this.http
      .post(`user/update`, formData, requestOptions)
      .pipe(map((data: Data) => data.data));
  }
}

import { Service } from "./../../models/service";
import { Router, ActivatedRoute } from "@angular/router";
import { DepartmentService } from "./../../services/department.service";
import { Department } from "src/app/models/department";
import { User } from "./../../models/user";
import { ErrorService } from "./../../services/error.service";
import { MaterialGroup } from "src/app/models/materialGroup";
import { Cut } from "./../../models/cut";
import { CutsService } from "./../../services/cuts.service";
import { MaterialService } from "./../../services/material.service";
import { Material } from "./../../models/material";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { StorageService } from "src/app/services/storage.service";
import * as XLSX from "xlsx";
import { UserService } from "src/app/services/user.service";
import { OrderService } from "src/app/services/order.service";
import { ServiceService } from "src/app/services/service.service";

import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: "app-show-order",
  templateUrl: "./show-order.component.html",
  styleUrls: ["./show-order.component.css"]
})
export class ShowOrderComponent implements OnInit {
  cuts: Cut[];

  apiUrl = environment.apiUrl;

  product: Material;
  productGroup: number;
  productId: number;

  uniqueMaterials: any[];
  uniqueMaterialIndex: number;

  material: any;
  materials: Material[];
  materialGroups: MaterialGroup[];
  sizes = [];
  size = {
    height: 2800,
    id: 0,
    width: 2070
  };

  editedCut = {
    id: "",
    order_id: "",
    material_id: "",
    height: "",
    width: "",
    count: "",
    rotation: 0,
    cleft: "",
    cright: "",
    ctop: "",
    cbottom: ""
  };

  deleteItem: number;
  deleteMaterial: any;
  formerMaterial: any;
  data: any;

  user: User;
  title: string;
  body: string;

  orderFile: any = "";
  sendOrderFile: any;
  department_id = 1;

  departments: Department[];
  loader: boolean;

  services: Service[];

  orderId: number;
  serviceRows = [];

  getMaterials(): boolean {
    return this.uniqueMaterials.every(item => item.name);
  }

  constructor(
    private materialService: MaterialService,
    private cutsService: CutsService,
    private storageService: StorageService,
    private errorService: ErrorService,
    private userService: UserService,
    private departmentService: DepartmentService,
    private orderService: OrderService,
    private router: Router,
    private serviceService: ServiceService,
    private route: ActivatedRoute
  ) {
    this.orderId = route.snapshot.params["orderIds"];
  }

  ngOnInit() {
    this.cutsService.$editedCut.subscribe(val => {
      this.editedCut = val;
      this.getServiceRows();
    });

    this.serviceService.getServices().subscribe(services => {
      this.services = services;
    });
    this.materialService.getMaterialGroups().subscribe(group => {
      this.materialGroups = group;
    });

    if (this.product) {
      this.productGroup = this.product.group_id;
      this.productId = this.product.id;

      this.materialService
        .getMaterials(this.productGroup)
        .subscribe(materials => {
          this.materials = materials.map(item => item);
        });
    }

    this.cutsService.getCuts(`${this.orderId}`).subscribe(
      cuts => {
        this.cuts = cuts;
        if (cuts.length) {
          this.editedCut = cuts[0];
          this.editedCut.cleft =
            this.editedCut.cleft === null ? "" : this.editedCut.cleft;
          this.editedCut.cright =
            this.editedCut.cright === null ? "" : this.editedCut.cright;
          this.editedCut.ctop =
            this.editedCut.ctop === null ? "" : this.editedCut.ctop;
          this.editedCut.cbottom =
            this.editedCut.cbottom === null ? "" : this.editedCut.cbottom;
          this.cutsService.setEditedCut(this.editedCut);
        }
        this.uniqueMaterials =
          cuts.length > 0
            ? [
                ...Array.from(
                  new Set(cuts.map((item: any) => item.material_id))
                )
              ]
            : [];

        if (this.uniqueMaterials.length > 0) {
          this.uniqueMaterials.map((item, index) => {
            return this.materialService.getMaterial(item).subscribe(data => {
              this.material = data;
              this.uniqueMaterials[index] = this.material.data;
              this.sizes[index] = this.material.sizes;
              if (index === 0) {
                this.uniqueMaterialIndex = index;
                this.productGroup = this.material.data.group_id;
                this.productId = this.material.data.id;

                this.materialService
                  .getMaterials(this.productGroup)
                  .subscribe(materials => {
                    this.materials = materials.map(item => item);
                  });
              }
            });
          });
        }
      },
      error =>
        error.status === 403 ? this.storageService.removeItem("token") : ""
    );
    this.materialService.getMaterialGroups().subscribe(group => {
      this.materialGroups = group;
    });

    this.departmentService
      .getDepartments()
      .subscribe(
        departments => (this.departments = departments),
        error => this.errorService.setError(error.error.error)
      );
  }

  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  changeTab(index: number) {
    this.size = this.sizes[index]
      ? this.sizes[index][0]
      : {
          height: 2800,
          id: 0,
          width: 2800
        };
    this.uniqueMaterialIndex = index;

    this.editedCut = {
      id: "",
      order_id: "",
      material_id: "",
      height: "",
      width: "",
      count: "",
      rotation: 0,
      cleft: "",
      cright: "",
      ctop: "",
      cbottom: ""
    };
    this.cutsService.setEditedCut(this.editedCut);
  }

  getGroupTitle(item: any) {
    const title: any = this.materialGroups
      ? this.materialGroups.filter(group => group.id == item)[0]
      : "";
    return title.name;
  }

  getFilteredCuts(id: number) {
    return this.cuts.filter(item => item.material_id == id);
  }

  totalCuts(id: number, type: string) {
    const filteredCuts = this.getFilteredCuts(id);
    const initialValue = 0;

    if (type === "material") {
      return filteredCuts
        .reduce((value, item) => value + item.price_material, initialValue)
        .toFixed(2);
    } else if (type === "service") {
      return filteredCuts
        .reduce((value, item) => value + item.price_service, initialValue)
        .toFixed(2);
    } else if (type === "square") {
      return filteredCuts
        .reduce(
          (value, item) =>
            value + (item.width / 1000) * (item.height / 1000) * item.count,
          initialValue
        )
        .toFixed(2);
    } else if (type === "count") {
      return filteredCuts.reduce(
        (value, item) => value + +item.count,
        initialValue
      );
    }
  }

  onCutClick(item: any) {
    this.editedCut = item;
    this.editedCut.cleft = item.cleft === null ? "" : item.cleft;
    this.editedCut.cright = item.cright === null ? "" : item.cright;
    this.editedCut.ctop = item.ctop === null ? "" : item.ctop;
    this.editedCut.cbottom = item.cbottom === null ? "" : item.cbottom;
    this.cutsService.setEditedCut(this.editedCut);
  }

  onPrintClick() {
    this.router.navigateByUrl(
      `/order/show/${this.orderId}(print:print/table/${this.orderId})`
    );
  }

  getServiceRows() {
    let serviceRows = [
      this.editedCut.ctop,
      this.editedCut.cbottom,
      this.editedCut.cright,
      this.editedCut.cleft
    ];
    this.serviceRows = [
      ...Array.from(new Set(serviceRows.map((item: any) => item)))
    ]
      .filter(item => !!item || item === 0)
      .map(item => {
        return {
          type:
            item === this.editedCut.ctop
              ? "ctop"
              : item === this.editedCut.cbottom
              ? "cbottom"
              : item === this.editedCut.cright
              ? "cright"
              : "cleft",
          value: item,
          width:
            (item === this.editedCut.ctop ? 1 : 0) +
            (item === this.editedCut.cbottom ? 1 : 0),
          height:
            (item === this.editedCut.cright ? 1 : 0) +
            (item === this.editedCut.cleft ? 1 : 0)
        };
      });
  }

  getService(item: any, type: string) {
    let title: any = this.services
      ? this.services.filter(trim => trim.id == item[type])[0]
      : "";
    return title;
  }

  onOrderCopy(id) {
    this.orderService.cloneOrder(id);
  }
}

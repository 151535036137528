import { NgxMaskModule } from "ngx-mask";
import { NgModule } from "@angular/core";
import { ErrorService } from "./../../services/error.service";
import { User } from "src/app/models/user";
import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-update-user",
  templateUrl: "./update-user.component.html",
  styleUrls: ["./update-user.component.css"]
})
@NgModule({
  imports: [NgxMaskModule.forChild()]
})
export class UpdateUserComponent implements OnInit {
  user: User = {
    first_name: "",
    last_name: "",
    email: "",
    phone: ""
  };

  constructor(
    private userService: UserService,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    this.userService
      .getUser()
      .subscribe(
        user => (this.user = this.userService.user = user),
        error => this.errorService.setError(error.error.error)
      );
  }

  onUpdateUser() {
    this.userService
      .updateUser(this.user)
      .subscribe(
        (user: User) =>
          this.userService
            .getUser()
            .subscribe(
              user => (this.user = this.userService.user = user),
              error => this.errorService.setError(error.error.error)
            ),
        error => this.errorService.setError(error.error.error)
      );
  }
}

import { Router, ActivatedRoute } from '@angular/router';
import { DepartmentService } from './../../services/department.service';
import { Department } from 'src/app/models/department';
import { User } from './../../models/user';
import { ErrorService } from './../../services/error.service';
import { MaterialGroup } from 'src/app/models/materialGroup';
import { Cut } from './../../models/cut';
import { CutsService } from './../../services/cuts.service';
import { MaterialService } from './../../services/material.service';
import { Material } from './../../models/material';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import * as XLSX from 'xlsx';
import { UserService } from 'src/app/services/user.service';
import { OrderService } from 'src/app/services/order.service';

import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
	selector: 'app-cuts-table',
	templateUrl: './cuts-table.component.html',
	styleUrls: [ './cuts-table.component.css' ]
})
export class CutsTableComponent implements OnInit {
	@ViewChild('width') widthField: ElementRef;
	@ViewChild('height') heightField: ElementRef;
	@ViewChild('count') countField: ElementRef;
	@ViewChild('file') fileField: ElementRef;

        apiUrl = environment.apiUrl;

	newCut = {
		order_id: '',
		material_id: '',
		height: '',
		width: '',
		count: '',
		rotation: 0,
		cleft: '',
		cright: '',
		ctop: '',
		cbottom: '',
		price_material: 0,
		price_service: 0
	};

	cuts: Cut[];

	product: Material;
	productGroup: number;
	productId: number;

	uniqueMaterials: any[];
	uniqueMaterialIndex: number;

	material: any;
	materials: Material[];
	materialGroups: MaterialGroup[];
	sizes = [];
	size = {
		height: 2800,
		id: 0,
		width: 2070
	};

	editedCut = {
		id: '',
		order_id: '',
		material_id: '',
		height: '',
		width: '',
		count: '',
		rotation: 0,
		cleft: '',
		cright: '',
		ctop: '',
		cbottom: ''
	};

	deleteItem: number;
	deleteMaterial: any;
	formerMaterial: any;
	data: any;

	user: User;
	title: string;
	body: string;

	orderFile: any = '';
	sendOrderFile: any;
	department_id = 1;

	departments: Department[];
	loader: boolean;

	orderFileNames: string[];
	orderId: string;

	intervalSave: any;
	intervalEdit: any;

	getMaterials(): boolean {
		return this.uniqueMaterials.every((item) => item.name);
	}

	constructor(
		private materialService: MaterialService,
		private cutsService: CutsService,
		private storageService: StorageService,
		private errorService: ErrorService,
		private userService: UserService,
		private departmentService: DepartmentService,
		private orderService: OrderService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.orderId = route.snapshot.params['id'];
	}

	ngOnInit() {
		this.cutsService.$editedCut.subscribe((val) => {
			this.editedCut = val;
		});
		this.userService.getUser().subscribe((user) => (this.user = this.userService.user = user));

		this.newCut.order_id = localStorage.getItem('orderID');
		this.newCut.material_id = localStorage.getItem('productID');
		this.product = JSON.parse(localStorage.getItem('product'));

		this.intervalSave = setInterval(() => {
			if (this.cuts && this.cuts.some((item) => !item.id) && !this.loader) {
				this.onSaveClick();
			}
		}, 10000);
		this.intervalEdit = setInterval(() => {
			if (this.editedCut.id) {
				this.onSaveEditCut();
			}
		}, 20000);

		if (this.product) {
			this.productGroup = this.product.group_id;
			this.productId = this.product.id;

			this.materialService.getMaterials(this.productGroup).subscribe((materials) => {
				this.materials = this.orderId
					? materials
					: materials.filter((item) => this.productId !== item.id).map((item) => item);
			});
		}

		this.cutsService.getCuts(this.newCut.order_id).subscribe((cuts) => {
			this.cuts = cuts;
			if (cuts.length) {
				this.cutsService.setTrimQnt(this.getUniqueTrims(cuts[0].material_id).length);
				this.cutsService.setTrims(this.getUniqueTrims(cuts[0].material_id));
				// this.editedCut = cuts[0];
				// this.editedCut.cleft = this.editedCut.cleft === null ? '' : this.editedCut.cleft;
				// this.editedCut.cright = this.editedCut.cright === null ? '' : this.editedCut.cright;
				// this.editedCut.ctop = this.editedCut.ctop === null ? '' : this.editedCut.ctop;
				// this.editedCut.cbottom = this.editedCut.cbottom === null ? '' : this.editedCut.cbottom;
				// this.cutsService.setEditedCut(this.editedCut);
			}
			this.uniqueMaterials =
				cuts.length > 0 ? [ ...Array.from(new Set(cuts.map((item: any) => item.material_id))) ] : [];

			if (this.uniqueMaterials.length > 0) {
				this.uniqueMaterials.map((item, index) => {
					return this.materialService.getMaterial(item).subscribe((data) => {
						this.material = data;
						this.uniqueMaterials[index] = this.material.data;
						this.sizes[index] = this.material.sizes;
						if (index === 0) {
							this.newCut.material_id = this.material.data.id;
							this.size =
								this.material.sizes.length > 0
									? this.material.sizes[0]
									: {
											height: 2800,
											id: 0,
											width: 2070
										};
							this.uniqueMaterialIndex = index;
							this.productGroup = this.material.data.group_id;
							this.productId = this.material.data.id;

							this.materialService.getMaterials(this.productGroup).subscribe((materials) => {
								this.materials = materials
									.filter((item) => this.uniqueMaterials.every((material) => material.id !== item.id))
									.map((item) => item);
							});
						}
					});
				});
			} else if (!this.orderId) {
				this.uniqueMaterials.push(this.product);
				this.uniqueMaterialIndex = 0;
			}
		}, (error) => (error.status === 403 ? this.storageService.removeItem('token') : ''));
		this.materialService.getMaterialGroups().subscribe((group) => {
			this.materialGroups = group;
		});

		this.departmentService
			.getDepartments()
			.subscribe(
				(departments) => (this.departments = departments),
				(error) => this.errorService.setError(error.error.error)
			);
	}

	ngOnDestroy() {
		clearInterval(this.intervalSave);
		clearInterval(this.intervalEdit);
	}

	changeTab(index: number) {
		this.onSelectChange(this.uniqueMaterials[index].group_id, 'group');
		this.onSelectChange(this.uniqueMaterials[index].id, 'product');
		this.newCut.material_id = this.uniqueMaterials[index].id;
		this.size = this.size =
			this.sizes && this.sizes.length > 0 && this.sizes[index] && this.sizes[index].length > 0
				? this.sizes[index][0]
				: {
						height: 2800,
						id: 0,
						width: 2070
					};
		this.uniqueMaterialIndex = index;
		this.materialService.getMaterials(this.productGroup).subscribe((materials) => {
			this.materials = materials
				.filter((item) => this.uniqueMaterials.every((material) => material.id !== item.id))
				.map((item) => item);
		});

		this.editedCut = {
			id: '',
			order_id: '',
			material_id: '',
			height: '',
			width: '',
			count: '',
			rotation: 0,
			cleft: '',
			cright: '',
			ctop: '',
			cbottom: ''
		};
		this.cutsService.setEditedCut(this.editedCut);
		this.cutsService.setTrimQnt(this.getUniqueTrims(+this.newCut.material_id).length);
		this.cutsService.setTrims(this.getUniqueTrims(+this.newCut.material_id));
	}

	getGroupTitle(item: any) {
		const title: any = this.materialGroups ? this.materialGroups.filter((group) => group.id == item)[0] : '';
		return title.name;
	}

	getFilteredCuts(id: number) {
		return this.cuts.filter((item) => item.material_id == id).sort((a, b) => a.id - b.id);
	}

	onSelectChange(value: any, type: string) {
		if (type === 'group') {
			this.materialService.getMaterials(this.productGroup).subscribe((materials) => {
				this.materials = materials
					.filter((item) => this.uniqueMaterials.every((material) => material.id !== item.id))
					.map((item) => item);
			});
		} else {
			this.productId = value;
		}
	}

	totalCuts(id: number, type: string) {
		const filteredCuts = this.getFilteredCuts(id);
		const initialValue = 0;

		if (type === 'material') {
			return filteredCuts.reduce((value, item) => value + item.price_material, initialValue).toFixed(2);
		} else if (type === 'service') {
			return filteredCuts.reduce((value, item) => value + item.price_service, initialValue).toFixed(2);
		} else if (type === 'square') {
			return filteredCuts
				.reduce((value, item) => value + item.width / 1000 * (item.height / 1000) * item.count, initialValue)
				.toFixed(2);
		} else if (type === 'count') {
			return filteredCuts.reduce((value, item) => value + +item.count, initialValue);
		}
	}

	addMaterial() {
		if (this.cuts && this.cuts.some((item) => !item.id) && !this.loader) {
			this.onSaveClick();
		}
		if (this.editedCut.id) {
			this.onSaveEditCut();
		}
		const index = +this.productId;
		const materials = this.materials;
		this.uniqueMaterials.push(materials.filter((item: Material) => item.id === index)[0]);
		this.uniqueMaterialIndex = this.uniqueMaterials.length - 1;
		this.newCut.material_id = this.uniqueMaterials[this.uniqueMaterialIndex].id;
		this.editedCut = {
			id: '',
			order_id: '',
			material_id: '',
			height: '',
			width: '',
			count: '',
			rotation: 0,
			cleft: '',
			cright: '',
			ctop: '',
			cbottom: ''
		};
		this.cutsService.setEditedCut(this.editedCut);
		this.cutsService.setTrimQnt(this.getUniqueTrims(+this.newCut.material_id).length);
		this.cutsService.setTrims(this.getUniqueTrims(+this.newCut.material_id));
		this.materialService.getMaterials(this.productGroup).subscribe((materials) => {
			this.materials = materials
				.filter((item) => this.uniqueMaterials.every((material) => material.id !== item.id))
				.map((item) => item);
		});
	}

	onCutClick(item: any, id: any) {
		if (this.cuts && this.cuts.some((item) => !item.id) && !this.loader) {
			this.onSaveClick();
		}
		if (this.editedCut.id && this.editedCut.id !== item.id) {
			this.onSaveEditCut();
		}
		if (item.id) {
			this.editedCut = item;
			this.editedCut.cleft = item.cleft === null ? '' : item.cleft;
			this.editedCut.cright = item.cright === null ? '' : item.cright;
			this.editedCut.ctop = item.ctop === null ? '' : item.ctop;
			this.editedCut.cbottom = item.cbottom === null ? '' : item.cbottom;
			this.cutsService.setEditedCut(this.editedCut);
			this.cutsService.setTrimQnt(this.getUniqueTrims(+this.newCut.material_id).length);
			this.cutsService.setTrims(this.getUniqueTrims(+this.newCut.material_id));
		}
	}

	onCutDelete(id: number) {
		this.deleteItem = id;
		$('#deleteItem').modal('show');
	}

	onDeleteMaterial(id: number, index: number) {
		this.deleteMaterial = {
			id,
			index
		};
		$('#deleteItem').modal('show');
	}

	onCutDeleteConfirm() {
		this.cuts = this.cuts.filter((item) => item.id !== this.deleteItem);
		this.cutsService.deleteCut(this.deleteItem).subscribe(
			(data: any) => {
				this.editedCut = {
					id: '',
					order_id: '',
					material_id: '',
					height: '',
					width: '',
					count: '',
					rotation: 0,
					cleft: '',
					cright: '',
					ctop: '',
					cbottom: ''
				};
				this.cutsService.setEditedCut(this.editedCut);
				this.cutsService.setTrimQnt(this.getUniqueTrims(+this.newCut.material_id).length);
				this.cutsService.setTrims(this.getUniqueTrims(+this.newCut.material_id));
			},
			(error) => this.errorService.setError(`Возникла ошибка при удалении детали`)
		);
	}

	onMaterialDeleteConfirm() {
		const filteredCuts = this.getFilteredCuts(this.deleteMaterial.id);
		this.uniqueMaterials = this.uniqueMaterials.filter((item) => item.id !== this.deleteMaterial.id);
		if (filteredCuts.length) {
			this.cutsService.deleteMaterialCut(+this.newCut.order_id, this.deleteMaterial.id).subscribe(
				(data: any) => {
					this.cutsService.getCuts(this.newCut.order_id).subscribe((cuts) => {
						this.cuts = cuts;
					});
				},
				(error) => this.errorService.setError(`Виникла помилка при видаленні матеріалу`)
			);
		}
		if (this.uniqueMaterials.length) {
			this.uniqueMaterialIndex = this.uniqueMaterials.length - 1;
			this.newCut.material_id = this.uniqueMaterials[this.uniqueMaterialIndex].id;
		}
		this.editedCut = {
			id: '',
			order_id: '',
			material_id: '',
			height: '',
			width: '',
			count: '',
			rotation: 0,
			cleft: '',
			cright: '',
			ctop: '',
			cbottom: ''
		};
		this.cutsService.setEditedCut(this.editedCut);
		this.cutsService.setTrimQnt(this.getUniqueTrims(+this.newCut.material_id).length);
		this.cutsService.setTrims(this.getUniqueTrims(+this.newCut.material_id));
		this.deleteMaterial = '';
		this.materialService.getMaterials(this.productGroup).subscribe((materials) => {
			this.materials = materials
				.filter((item) => this.uniqueMaterials.every((material) => material.id !== item.id))
				.map((item) => item);
		});
	}

	onEditMaterial(id: number, index: number) {
		if (this.cuts && this.cuts.some((item) => !item.id) && !this.loader) {
			this.onSaveClick();
		}
		if (this.editedCut.id) {
			this.onSaveEditCut();
		}
		this.formerMaterial = {
			id,
			index
		};
		this.editedCut = {
			id: '',
			order_id: '',
			material_id: '',
			height: '',
			width: '',
			count: '',
			rotation: 0,
			cleft: '',
			cright: '',
			ctop: '',
			cbottom: ''
		};
		$('#choose-material').modal('show');
	}

	onMaterialEditConfirm() {
		this.editedCut = {
			id: '',
			order_id: '',
			material_id: '',
			height: '',
			width: '',
			count: '',
			rotation: 0,
			cleft: '',
			cright: '',
			ctop: '',
			cbottom: ''
		};
		const index = +this.productId;
		this.cutsService.editMaterialCut(+this.newCut.order_id, this.formerMaterial.id, index).subscribe(
			(data: any) => {
				this.cutsService.getCuts(this.newCut.order_id).subscribe((cuts) => {
					this.cuts = cuts;
				});
			},
			(error) => this.errorService.setError(`Виникла помилка при видаленні матеріалу`)
		);
		this.uniqueMaterials[this.formerMaterial.index] = this.materials.filter((item) => item.id == this.productId)[0];

		this.newCut.material_id = this.uniqueMaterials[this.uniqueMaterialIndex].id;
		this.cutsService.setTrimQnt(this.getUniqueTrims(+this.newCut.material_id).length);
		this.cutsService.setTrims(this.getUniqueTrims(+this.newCut.material_id));
		this.formerMaterial = '';
		this.materialService.getMaterials(this.productGroup).subscribe((materials) => {
			this.materials = materials
				.filter((item) => this.uniqueMaterials.every((material) => material.id !== item.id))
				.map((item) => item);
		});
		$('#choose-material').modal('toggle');
	}

	onSaveClick() {
		if (this.newCut.height === '' && this.newCut.width === '' && this.newCut.count === '') {
			this.loader = true;
			let cuts: any = {
				data: JSON.stringify(this.cuts.filter((item) => !item.id)),
				order_id: localStorage.getItem('orderID')
			};
			this.editedCut.id ? this.onSaveEditCut() : '';
			this.cutsService.createCut(cuts).subscribe((data: any) =>
				this.cutsService.getCuts(localStorage.getItem('orderID')).subscribe((cuts) => {
					this.loader = !this.loader;
					this.cuts = cuts;
					this.cutsService.setTrimQnt(this.getUniqueTrims(+this.newCut.material_id).length);
					this.cutsService.setTrims(this.getUniqueTrims(+this.newCut.material_id));
        },
          error => {
            if (error.status === 403) {
              this.storageService.removeItem("token");
              this.router.navigateByUrl("/signing");
            } else {
              this.errorService.setError(error.error.error);
            }
          })
			);
    } else {
      this.onCountBlur()
    }

	}

	onSaveEditCut(type: any = '') {
		if (this.size.height >= +this.editedCut.height && this.size.width >= +this.editedCut.width) {
			let id = this.editedCut.id;
			this.cutsService.updateCut(this.editedCut).subscribe((data: any) =>
				this.cutsService.getCuts(this.newCut.order_id).subscribe((cuts) => {
					let index = this.cuts.findIndex((item) => item.id === id);
					this.cuts[index] = cuts[index];
        },
          error => {
            if (error.status === 403) {
              this.storageService.removeItem("token");
              this.router.navigateByUrl("/signing");
            } else {
              this.errorService.setError(error.error.error);
            }
          })
			);
			setTimeout(() => {
				if (type) {
					this.editedCut = {
						id: '',
						order_id: '',
						material_id: '',
						height: '',
						width: '',
						count: '',
						rotation: 0,
						cleft: '',
						cright: '',
						ctop: '',
						cbottom: ''
					};
					this.cutsService.setEditedCut(this.editedCut);
					this.cutsService.setTrimQnt(this.getUniqueTrims(+this.newCut.material_id).length);
					this.cutsService.setTrims(this.getUniqueTrims(+this.newCut.material_id));
				}
			}, 200);
		}
	}

	onInputBlur(type: string) {
		if (
			type === 'height' &&
			this.newCut.height &&
			(+this.newCut.height < 20 || +this.newCut.height > this.size.height)
		) {
			this.errorService.setError(`Минимальная длина - 20 мм, максимальная -  ${this.size.height}`);
		}
		if (
			type === 'width' &&
			this.newCut.width &&
			(+this.newCut.width < 20 || +this.newCut.width > this.size.width)
		) {
			this.errorService.setError(`Минимальная ширина - 20 мм, максимальная - ${this.size.width}`);
		}
	}

	keytab(event) {
		let element = event.srcElement.nextElementSibling;
		if (element == null) this.onCountBlur();
		else element.focus();
	}

	onCountBlur() {
		let elmnt = document.querySelectorAll('.srollToRow');
		let cut: any = this.newCut;
		let heightArray = document.querySelectorAll('.form-control.height');
		let widthArray = document.querySelectorAll('.form-control.width');
		let countArray = document.querySelectorAll('.form-control.count');
		if (
			this.newCut.count &&
			this.newCut.width &&
			this.newCut.height &&
			+this.newCut.width <= this.size.width &&
			+this.newCut.height <= this.size.height &&
			+this.newCut.width > 19 &&
			+this.newCut.height > 19
		) {
			this.cuts.push(cut);

			this.newCut = {
				order_id: this.newCut.order_id,
				material_id: this.newCut.material_id,
				height: '',
				width: '',
				count: '',
				rotation: 0,
				cleft: '',
				cright: '',
				ctop: '',
				cbottom: '',
				price_material: 0,
				price_service: 0
			};
			setTimeout(() => {
				elmnt.forEach((item) => item.scrollIntoView());
				let height = <HTMLInputElement>heightArray[this.uniqueMaterialIndex];
				height.focus();
			}, 100);
		} else if (+this.newCut.height > this.size.height || !this.newCut.height || +this.newCut.height < 20) {
			let height = <HTMLInputElement>heightArray[this.uniqueMaterialIndex];
			height.focus();
		} else if (+this.newCut.width > this.size.width || !this.newCut.width || +this.newCut.width < 20) {
			let width = <HTMLInputElement>widthArray[this.uniqueMaterialIndex];
			width.focus();
		} else {
			let count = <HTMLInputElement>countArray[this.uniqueMaterialIndex];
			count.focus();
		}
	}

	onFileChange(evt: any) {
		let elmnt = document.querySelectorAll('.srollToRow');
		/* wire up file reader */
		const target: DataTransfer = <DataTransfer>evt.target;
		if (target.files.length !== 1) throw new Error('Cannot use multiple files');
		const reader: FileReader = new FileReader();
		reader.onload = (e: any) => {
			/* read workbook */
			const bstr: string = e.target.result;
			const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

			/* grab first sheet */
			const wsname: string = wb.SheetNames[0];
			const ws: XLSX.WorkSheet = wb.Sheets[wsname];

			/* save data */
			this.data = XLSX.utils.sheet_to_json(ws, { header: 1 }).filter((item) => +item[0] && +item[1]);

			this.data.map((item) => {
				item = {
					order_id: localStorage.getItem('orderID'),
					material_id: this.productId,
					height: item[0],
					width: item[1],
					count: item[2],
					rotation: 0,
					cleft: '',
					cright: '',
					ctop: '',
					cbottom: '',

					price_material: 0,
					price_service: 0
				};
				this.cuts.push(item);
			});
			if (this.cuts && this.cuts.some((item) => !item.id) && !this.loader) {
				this.onSaveClick();
			}
			setTimeout(() => elmnt.forEach((item) => item.scrollIntoView()), 100);
			this.fileField.nativeElement.value = '';
		};
		reader.readAsBinaryString(target.files[0]);
	}

	onSendClick() {
		this.title = `№ Заказа ${this.newCut.order_id} от ${this.user.first_name} ${this.user.last_name}`;
	}

	onSelectDepartmentChange(id: any) {
		this.department_id = id;
	}

	handleFileInput(files: any) {
		this.sendOrderFile = this.sendOrderFile ? [ ...this.sendOrderFile, ...Array.from(files) ] : Array.from(files);
		this.getOrderFileNames();
	}

	onSendOrderClick(id: any) {
		let orderData: any = {
			craft_id: this.department_id,
			title: this.title,
			body: this.body
		};

		if (this.sendOrderFile) {
			orderData.file = this.sendOrderFile;
		}

		this.orderService.sendOrder(this.newCut.order_id, orderData).subscribe((data: any) => {
			if (data) {
				this.body = '';
				this.sendOrderFile = '';
				this.router.navigateByUrl(`/order/show/${this.newCut.order_id}`);
			}
		});
	}

	checkRotation() {
		this.editedCut.rotation = this.editedCut.rotation ? 0 : 1;
	}

	editPress(event) {
		let element = event.srcElement.parentNode.nextSibling.firstChild;
		if (element.localName === 'span') this.onSaveEditCut('button');
		else element.focus();
	}

	onPrintClick() {
		if (this.cuts && this.cuts.some((item) => !item.id) && !this.loader) {
			this.onSaveClick();
		}

		this.router.navigateByUrl(
			`/order${this.orderId ? '/' + this.orderId : ''}(print:print/table/${this.newCut.order_id})`
		);
	}

	getOrderFileNames() {
		let urlArray = [];

		this.sendOrderFile.map((item) => {
			let reader = new FileReader();
			reader.onload = () => {
				item.type.split('/')[0] === 'image' ? urlArray.push(reader.result) : urlArray.push(item.name);
			};
			reader.readAsDataURL(item);
		});
		this.orderFileNames = urlArray;
	}

	getUniqueTrims(id: number) {
		let cuts = this.getFilteredCuts(id);

		let uniqueTrims = [
			...Array.from(new Set(cuts.map((item: any) => item.cleft))),
			...Array.from(new Set(cuts.map((item: any) => item.cright))),
			...Array.from(new Set(cuts.map((item: any) => item.ctop))),
			...Array.from(new Set(cuts.map((item: any) => item.cbottom)))
		];
		uniqueTrims = [ ...Array.from(new Set(uniqueTrims.map((item: any) => item))) ].filter((item) => item);
		return uniqueTrims;
	}

	onOrderFileDelete(index: number) {
		this.orderFileNames = this.orderFileNames.filter((item, i) => index !== i);
		this.sendOrderFile = this.sendOrderFile.filter((item, i) => index !== i);
	}

	checkStringBase(index: number) {
		return this.sendOrderFile[index].type.split('/')[0] === 'image';
	}
}

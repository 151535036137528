import { Component, OnInit } from "@angular/core";

import { MaterialService } from "src/app/services/material.service";
import "rxjs";
import { Material } from "src/app/models/material";
import { MaterialGroup } from "src/app/models/materialGroup";
import { LoaderService } from "src/app/services/loader.service";
import { StorageService } from "src/app/services/storage.service";

import { environment } from 'src/environments/environment';

@Component({
  selector: "app-materials",
  templateUrl: "./materials.component.html",
  styleUrls: ["./materials.component.css"]
})
export class MaterialsComponent implements OnInit {
  materials: Material[];
  materialGroups: MaterialGroup[];
  loader: boolean = true;
  apiUrl = environment.apiUrl;

  table: string = "listView";
  items = [
    { name: "listView", active: true, img: "assets/img/list.png" },
    { name: "cardView", active: false, img: "assets/img/menu.png" }
  ];

  constructor(
    private materialService: MaterialService,
    private loaderService: LoaderService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.loaderService.$loader.subscribe(val => {
      this.loader = val;
    });
    this.loaderService.setLoader(true);

    this.materialService.getMaterialGroups().subscribe(group => {
      this.materialGroups = group;
      this.materialService
        .getMaterials(this.materialGroups[0].id)
        .subscribe(materials => {
          this.loaderService.setLoader(false);
          this.materials = materials.map(item => {
            item.groupName =
              group[group.findIndex(el => el.id === item.group_id)].name;
            return item;
          });
        });
    });
  }

  toggleClass() {
    this.items.forEach(button => {
      if (!button.active) {
        this.table = button.name;
      }
      return (button.active = !button.active);
    });
  }

  onSelectChange(id) {
    this.loaderService.setLoader(true);
    this.materialService.getMaterials(id).subscribe(materials => {
      this.loaderService.setLoader(false);
      let materialGroup = this.materialGroups;
      this.materials = materials.map(item => {
        item.groupName =
          materialGroup[
            materialGroup.findIndex(el => el.id === item.group_id)
          ].name;
        return item;
      });
    });
  }

  getMaterial(id, material) {
    this.storageService.setItem("product", JSON.stringify(material));
    this.materialService.material = this.materials.filter(
      material => material.id === id
    )[0];
  }
}

import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit {
  changePassword = {
    password: "",
    new_password: "",
    password_confirm: ""
  };

  constructor(private userService: UserService) {}

  ngOnInit() {}

  onSubmitChange() {
    this.userService.changePassword(this.changePassword);
    this.changePassword = {
      password: "",
      new_password: "",
      password_confirm: ""
    };
  }
}

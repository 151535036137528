import { Component, OnInit } from "@angular/core";
import { MenuToggleService } from "src/app/services/menu-toggle.service";
import { MaterialService } from "src/app/services/material.service";
import { User } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";
import { LoaderService } from "src/app/services/loader.service";
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  visible: boolean = false;
  search: string = "";
  logged: boolean = false;
  user: User;
  materialGroup: number;

  constructor(
    private menuToggleService: MenuToggleService,
    private materialService: MaterialService,
    private userService: UserService,
    private loaderService: LoaderService,
    private storageService: StorageService
  ) {}

  public toggleBox(): void {
    this.visible = !this.visible;
    this.menuToggleService.setToggle(this.visible);
  }

  ngOnInit() {
    this.logged = !!localStorage.getItem("token");
    this.materialService.$materialGroup.subscribe(val => {
      this.materialGroup = val;
    });

    this.logged
      ? this.userService
          .getUser()
          .subscribe(user => (this.user = this.userService.user = user))
      : "";

    this.storageService.watchStorage().subscribe(data => {
      if (data.key === "token") {
        data.type === "set"
          ? this.userService
              .getUser()
              .subscribe(user => (this.user = this.userService.user = user))
          : "";
        this.logged = data.type === "set";
      }
    });

    this.menuToggleService.$toggle.subscribe(val => {
      this.visible = val;
    });
  }

  onSearch() {
    if (this.search && this.search.length > 2) {
      this.loaderService.setLoader(true);
      this.materialService.searchMaterials(this.search).subscribe(materials => {
        this.loaderService.setLoader(false);
        this.materialService.materials = materials;
      });
    } else {
      this.materialService.getMaterials(this.materialGroup).subscribe(data => {
        this.materialService.materials = data;
      });
      // console.log(this.materialGroup);
    }
  }
}

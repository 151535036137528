import { Component, OnInit } from "@angular/core";
import { Material } from "src/app/models/material";
import { MaterialService } from "src/app/services/material.service";
import { OrderService } from "src/app/services/order.service";
import { Router } from "@angular/router";
import { StorageService } from "src/app/services/storage.service";
import { LoaderService } from "src/app/services/loader.service";

import { environment } from 'src/environments/environment';

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.css"]
})
export class ProductComponent implements OnInit {
  material: Material;
  token: string;
  sizes: any;
  disabled: boolean = false;
  apiUrl = environment.apiUrl;

  constructor(
    private materialService: MaterialService,
    private orderService: OrderService,
    private storageService: StorageService,

    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.token = localStorage.getItem("token");
    this.loaderService.$loader.subscribe(val => {
      this.disabled = val;
    });
    this.materialService
      .getMaterial(JSON.parse(localStorage.getItem("product")).id)
      .subscribe(data => {
        this.sizes = data.sizes;
      });
    this.material = this.materialService.material
      ? this.materialService.material
      : JSON.parse(localStorage.getItem("product"));
  }

  onBuyClick(event, id) {
    event.preventDefault();
    this.storageService.setItem("productID", id);
    this.orderService.makeOrder(id);
  }
}
